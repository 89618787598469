import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { ROUTE_PATH } from '../../constants/paths'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import { useTheme } from '@emotion/react'

const useStyles = makeStyles({
    backgroundColorApp: {
        backgroundColor: '#12203A'
    },
    marginTopBar: {
        marginLeft: 20,
        marginRight: 20,
    }
})

export default function MenuItems() {
    const { employee = {} } = useContext(AppContext)

    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [openSetting, setOpenSetting] = useState(false)

    const theme = useTheme()
    const primaryMainColor = theme.palette.primary.main

    const handleClick = () => {
        setOpen(!open)
    }

    const handleClickSetting = () => {
        setOpenSetting(!openSetting)
    }

    const [selectedIndex, setSelectedIndex] = useState(ROUTE_PATH.HOME)

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#12203A', }} style={{ height: '100vh' }}>
            <List component="nav" aria-label="main mailbox folders">
                {/* <ListItemButton
                    selected={selectedIndex === ROUTE_PATH.HOME}
                    onClick={(event) => {
                        setSelectedIndex(ROUTE_PATH.HOME)
                        navigate(ROUTE_PATH.HOME)
                    }}
                >
                    <ListItemText primary="แดชบอร์ด" style={{ color: primaryMainColor, marginLeft: 20, fontWeight: 'bold' }} />
                </ListItemButton>
                <Divider color="#888888" /> */}
                <ListItemButton onClick={handleClick} >
                    <ListItemText primary="การจัดการ" style={{ color: primaryMainColor, marginLeft: 20 }} />
                    {open ? <ExpandLess style={{ color: primaryMainColor }} /> : <ExpandMore style={{ color: primaryMainColor }} />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {employee.role === ROLES.AdminLing
                        && <List component="div" disablePadding>
                            <ListItemButton selected={selectedIndex === ROUTE_PATH.ENTERPRISE} sx={{ pl: 4 }}

                                onClick={(event) => {
                                    setSelectedIndex(ROUTE_PATH.ENTERPRISE)
                                    navigate(ROUTE_PATH.ENTERPRISE)
                                }}
                            >
                                <ListItemText primary="การจัดการ บริษัท" style={{ color: primaryMainColor, marginLeft: 20 }} />
                            </ListItemButton>
                        </List>
                    }
                    {employee.role !== ROLES.Staff &&
                        <List component="div" disablePadding>
                            <ListItemButton selected={selectedIndex === ROUTE_PATH.AREA_MANAGEMENT} sx={{ pl: 4 }}

                                onClick={(event) => {
                                    setSelectedIndex(ROUTE_PATH.AREA_MANAGEMENT)
                                    navigate(ROUTE_PATH.AREA_MANAGEMENT)
                                }}
                            >
                                <ListItemText primary="การจัดการ พื้นที่" style={{ color: primaryMainColor, marginLeft: 20 }} />
                            </ListItemButton>
                        </List>
                    }
                    {employee.role !== ROLES.Staff &&
                        <List component="div" disablePadding>
                            <ListItemButton selected={selectedIndex === ROUTE_PATH.TEAM} sx={{ pl: 4 }}

                                onClick={(event) => {
                                    setSelectedIndex(ROUTE_PATH.TEAM)
                                    navigate(ROUTE_PATH.TEAM)
                                }}
                            >
                                <ListItemText primary="การจัดการ ทีม" style={{ color: primaryMainColor, marginLeft: 20 }} />
                            </ListItemButton>
                        </List>
                    }
                    <List component="div" disablePadding>
                        <ListItemButton selected={selectedIndex === ROUTE_PATH.STAFF} sx={{ pl: 4 }}

                            onClick={(event) => {
                                setSelectedIndex(ROUTE_PATH.STAFF)
                                navigate(ROUTE_PATH.STAFF)
                            }}
                        >
                            <ListItemText primary="การจัดการ พนักงาน" style={{ color: primaryMainColor, marginLeft: 20 }} />
                        </ListItemButton>
                    </List>
                    {/* <List component="div" disablePadding>
                        <ListItemButton selected={selectedIndex === ROUTE_PATH.USER} sx={{ pl: 4 }}

                            onClick={(event) => {
                                setSelectedIndex(ROUTE_PATH.USER)
                                navigate(ROUTE_PATH.USER)
                            }}
                        >
                            <ListItemText primary="การจัดการ เกษตรกร" style={{ color: primaryMainColor, marginLeft: 20 }} />
                        </ListItemButton>
                    </List> */}
                    {/* <List component="div" disablePadding>
                        <ListItemButton selected={selectedIndex === ROUTE_PATH.PLOT} sx={{ pl: 4 }} onClick={(e) => {
                            setSelectedIndex(ROUTE_PATH.PLOT)
                            navigate(ROUTE_PATH.PLOT)
                        }}>
                            <ListItemText primary="การจัดการ แปลง" style={{ color: primaryMainColor, marginLeft: 20 }} />
                        </ListItemButton>
                    </List> */}
                </Collapse>
                {employee.role !== ROLES.Staff &&
                    <>
                        <Divider color="#888888" />
                        <ListItemButton onClick={handleClickSetting} >
                            <ListItemText primary="ตั้งค่า" style={{ color: primaryMainColor, marginLeft: 20 }} />
                            {openSetting ? <ExpandLess style={{ color: primaryMainColor }} /> : <ExpandMore style={{ color: primaryMainColor }} />}
                        </ListItemButton>
                        <Collapse in={openSetting} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton selected={selectedIndex === ROUTE_PATH.ROLE_MANAGEMENT} sx={{ pl: 4 }}

                                    onClick={(event) => {
                                        setSelectedIndex(ROUTE_PATH.ROLE_MANAGEMENT)
                                        navigate(ROUTE_PATH.ROLE_MANAGEMENT)
                                    }}
                                >
                                    <ListItemText primary="ตำแหน่ง" style={{ color: primaryMainColor, marginLeft: 20 }} />
                                </ListItemButton>
                            </List>
                            <List component="div" disablePadding>
                                <ListItemButton selected={selectedIndex === ROUTE_PATH.SIZE} sx={{ pl: 4 }}

                                    onClick={(event) => {
                                        setSelectedIndex(ROUTE_PATH.SIZE)
                                        navigate(ROUTE_PATH.SIZE)
                                    }}
                                >
                                    <ListItemText primary="หน่วยวัดขนาดแปลง" style={{ color: primaryMainColor, marginLeft: 20 }} />
                                </ListItemButton>
                            </List>
                            {
                                employee.role === ROLES.AdminLing ||
                                    employee.role === ROLES.Admin ?
                                    <List component="div" disablePadding>
                                        <ListItemButton selected={selectedIndex === ROUTE_PATH.API_SETTINGS} sx={{ pl: 4 }}

                                            onClick={(event) => {
                                                setSelectedIndex(ROUTE_PATH.API_SETTINGS)
                                                navigate(ROUTE_PATH.API_SETTINGS)
                                            }}
                                        >
                                            <ListItemText primary="API" style={{ color: primaryMainColor, marginLeft: 20 }} />
                                        </ListItemButton>
                                    </List> : <></>
                            }
                            {employee.role === ROLES.AdminLing
                                || employee.role === ROLES.Directer
                                || employee.role === ROLES.Admin
                                || employee.role === ROLES.AreaManager
                                ? <List component="div" disablePadding>
                                    <ListItemButton selected={selectedIndex === ROUTE_PATH.PLANTS} sx={{ pl: 4 }}

                                        onClick={(event) => {
                                            setSelectedIndex(ROUTE_PATH.PLANTS)
                                            navigate(ROUTE_PATH.PLANTS)
                                        }}
                                    >
                                        <ListItemText primary="พืช" style={{ color: primaryMainColor, marginLeft: 20 }} />
                                    </ListItemButton>
                                </List>
                                : <></>
                            }
                            {employee.role === ROLES.AdminLing
                                || employee.role === ROLES.Directer
                                || employee.role === ROLES.Admin
                                || employee.role === ROLES.AreaManager
                                ? <List component="div" disablePadding>
                                    <ListItemButton selected={selectedIndex === ROUTE_PATH.STANDARD} sx={{ pl: 4 }}

                                        onClick={(event) => {
                                            setSelectedIndex(ROUTE_PATH.STANDARD)
                                            navigate(ROUTE_PATH.STANDARD)
                                        }}
                                    >
                                        <ListItemText primary="ค่ามาตรฐาน" style={{ color: primaryMainColor, marginLeft: 20 }} />
                                    </ListItemButton>
                                </List>
                                : <></>
                            }
                            {employee.role === ROLES.Directer
                                || employee.role === ROLES.Admin
                                || employee.role === ROLES.AreaManager
                                ?
                                <List component="div" disablePadding>
                                    <ListItemButton selected={selectedIndex === ROUTE_PATH.SCHEDULE_MANAGEMENT} sx={{ pl: 4 }}

                                        onClick={(event) => {
                                            setSelectedIndex(ROUTE_PATH.SCHEDULE_MANAGEMENT)
                                            navigate(ROUTE_PATH.SCHEDULE_MANAGEMENT)
                                        }}
                                    >
                                        <ListItemText primary="การกำหนดการ" style={{ color: primaryMainColor, marginLeft: 20 }} />
                                    </ListItemButton>
                                </List>
                                : <></>
                            }
                        </Collapse>
                    </>
                }
            </List>
        </Box>
    )
}