import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Grid, } from '@mui/material'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import { googleMapAPIKey, googleMapLibraries } from '../../constants/common'
import useCategoryEnterprise from '../../reactHook/useCategoryEnterprise'
import { Chart as ChartJS } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import StyledDataGrid from "../../components/StyledDataGrid"
import { AppContext } from '../../contexts/AppContext'
import { ROLES } from '../../constants/enums'
import { getAllAreaDashboard, getAllCoordinatesByEnterpriseId, getAllPlotDashboard } from '../../services/dashboard'
import { API_RES_STATUS } from '../../constants/apiResponseStatus'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { getDefaultMeasurementDisplay } from '../../helper/measurementHelper'
import { getDefaultMeasurementByEnterpriseId } from '../../services/measurement'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#12203A",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#D3DCEC",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 1,
    },
}))

export default function HomePage() {
    // ปิดการใช้งาน
    return <></>
    const { employee = {} } = useContext(AppContext)
    const className = useStyles()
    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const { enterprises } = useCategoryEnterprise()
    const [tableLoading, setTableLoading] = useState(false)
    const [tableTotalRow, setTableTotalRow] = useState(0)
    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tablePageSize, setTablePageSize] = useState(10)
    const libraries = ["places"]
    const [markers, setMarkers] = useState([])
    const [amountPlot, setAmountPlot] = useState(0)
    const [amountUser, setAmountUser] = useState(0)
    const [totalAreaSize, setTotalAreaSize] = useState(0)
    const [enterpriseName, setEnterpriseName] = useState(employee.enterpriseName)
    const [listPlot, setListPlot] = useState([])
    const [listArea, setListArea] = useState([])
    const [displayTotalSize, setDisplayTotalSize] = useState(null)

    useEffect(() => {
        loadCoordinates(employee.enterpriseId)
        loadPlot(employee.enterpriseId)
        loadArea(employee.enterpriseId)
    }, [])

    const loadCoordinates = async (enterpriseId) => {
        const axiosRes = await getAllCoordinatesByEnterpriseId(enterpriseId)
        const json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        console.log("data Coordinates >>> ", data)
        if (status === API_RES_STATUS.success) {
            setAmountPlot(data.amountPlot)
            setAmountUser(data.amountUser)
            setTotalAreaSize(data.totalAreaSize.toFixed(3))
            setMarkers(data.listCoordinates)
            let defualtMeasurementName = await getMeasurementByEnterpriseId(enterpriseId)
            let displayMeasurement = await getDefaultMeasurementDisplay(defualtMeasurementName, data.totalSqMeter)
            setDisplayTotalSize(displayMeasurement)
        }
    }

    const getMeasurementByEnterpriseId = async (enterpriseId) => {
        const axiosRes = await getDefaultMeasurementByEnterpriseId(enterpriseId)
        const json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {
            return data.measurementName
        }
    }

    const loadPlot = async (enterpriseId) => {
        const axiosRes = await getAllPlotDashboard(enterpriseId)
        const json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        console.log("data loadPlot Barrrr >>> >>> ", data)
        if (status === API_RES_STATUS.success) {
            setListPlot(data)
        }
    }

    const loadArea = async (enterpriseId) => {
        const axiosRes = await getAllAreaDashboard(enterpriseId)
        const json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        console.log("data loadArea >>> >>> ", data)
        if (status === API_RES_STATUS.success) {
            setListArea(data)
        }
    }

    const mapContainerStyle = {
        width: "100%",
        height: "55vh"
    }

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapAPIKey,
        libraries: googleMapLibraries,
    })

    const center = {
        lat: 13.822132,
        lng: 100.531547
    }

    const options = {
        disableDefaultUI: false,
        mapTypeControl: true,
        streetViewControl: false,
        mapTypeId: 'hybrid'
    }

    if (loadError) return "Error loading maps"
    if (!isLoaded) return "loading Maps"

    const onInputChange = (value, key) => {
        setFilterEnterpriseId(value)
        loadCoordinates(value)
        loadPlot(value)
        loadArea(value)
        let enterprise = enterprises.filter((Item) => {
            if (Item.id.match(value))
                return Item
        })

        enterprise.map((item) => {
            setEnterpriseName(item.name)
        })
    }
    const columns = [
        { label: "ลำดับ", minWidth: 50, align: 'left', },
        { label: "พื้นที่", minWidth: 120, align: 'center' },
        { label: "ทีม", minWidth: 120, align: 'center' },
        { label: "แปลงทั้งหมด", minWidth: 120, align: 'center' },
    ]

    const data = {
        labels: listPlot.map((data) => {
            let monthName
            switch (data.createDateTimeMonth) {
                case 1:
                    return monthName = "มกราคม"
                case 2:
                    return monthName = "กุมภาพันธ์"
                case 3:
                    return monthName = "มีนาคม"
                case 4:
                    return monthName = "เมษายน"
                case 5:
                    return monthName = "พฤษภาคม"
                case 6:
                    return monthName = "มิถุนายน"
                case 7:
                    return monthName = "กรกฎาคม"
                case 8:
                    return monthName = "สิงหาคม"
                case 9:
                    return monthName = "กันยายน"
                case 10:
                    return monthName = "ตุลาคม"
                case 11:
                    return monthName = "พฤษจิกายน"
                case 12:
                    return monthName = "ธันวาคม"
                default:
                    break
            }
            return (
                monthName
            )
        }),
        datasets: [
            {
                label: 'Plots',
                data: listPlot.map((data) => data.amountPlot),
                backgroundColor: '#F08E26',
            },

        ],
    }

    return (
        <div className={className.p40}>

            {employee.role === ROLES.AdminLing &&
                <Grid container rowSpacing={1} >
                    <Grid xs={6}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                                <InputLabel >เลือกบริษัท</InputLabel>
                                <Select
                                    label="เลือกบริษัท"
                                    value={filterEnterpriseId}
                                    onChange={(e) => { onInputChange(e.target.value, 'enterpriseId') }}
                                >
                                    {enterprises.map((e) => {
                                        return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
            }
            <Box className={className.boxEnterPrise}>
                <Typography component="h6" variant="h6" color={'white'}
                    className={className.labelInBoxCity}>
                    บริษัท {enterpriseName}
                </Typography>
            </Box>
            <Grid container direction={'row'} spacing={3} >
                <Grid item xs={12} sm={12}>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={7}
                        center={center}
                        options={options}
                    >
                        {markers.map((item) => {
                            let position = { lat: item.latitude, lng: item.longitude }
                            return (
                                <Marker
                                    key={item.id}
                                    position={position}
                                >
                                </Marker>
                            )
                        })
                        }
                    </GoogleMap>
                </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <div className={className.paper4}>
                            <Typography component="h6" variant="h6" color={'#F08E26'} >
                                ขนาดพื้นที่ดูแลทั้งหมด
                            </Typography>
                            <div className={className.card_center}>
                                <Typography component="h4" variant="h4" color={'#fff'} style={{ marginTop: 10 }}>
                                    {displayTotalSize}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={className.paper4}>
                            <Typography component="h6" variant="h6" color={'#F08E26'} >
                                จำนวนแปลงทั้งหมด
                            </Typography>
                            <div className={className.card_center}>
                                <Typography component="h4" variant="h4" color={'#fff'} style={{ marginTop: 10 }}>
                                    {amountPlot} แปลง
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={className.paper4}>
                            <Typography component="h6" variant="h6" color={'#F08E26'} >
                                จำนวนเกษตรกรทั้งหมด
                            </Typography>
                            <div className={className.card_center}>
                                <Typography component="h4" variant="h4" color={'#fff'} style={{ marginTop: 10 }}>
                                    {amountUser} คน
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }} mt={6}>
                <div style={{ fontSize: 18 }}>
                    จำนวนแปลงลงทะเบียนทั้งหมด / เดือน
                </div>
                <div elevation={6} className={className.paperChart}>
                    <Bar data={data} options={{
                        scales: {
                            yAxes: {
                                grid: {
                                    drawBorder: true,
                                    color: '#FFFFFF',
                                },
                                ticks: {
                                    beginAtZero: true,
                                    color: 'white',
                                    fontSize: 12,
                                }
                            },
                            xAxes: {
                                grid: {
                                    drawBorder: true,
                                    color: '#FFFFFF',
                                },
                                ticks: {
                                    beginAtZero: true,
                                    color: 'white',
                                    fontSize: 12,
                                }
                            },
                        }
                    }} />
                </div>
            </Box>
            <div style={{ fontSize: 18, marginTop: 40, marginBottom: 20 }}>
                จำนวนแปลง/พื้นที่
            </div>
            <Box sx={{ height: '80vh', width: '100%' }}>
                <TableContainer >
                    <Table stickyHeader aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>ลำดับ</StyledTableCell>
                                <StyledTableCell align="center">พื้นที่</StyledTableCell>
                                <StyledTableCell align="center">ทีม</StyledTableCell>
                                <StyledTableCell align="center">แปลงทั้งหมด</StyledTableCell>
                            </TableRow>
                            {/* <TableRow>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </TableRow> */}
                        </TableHead>
                        <TableBody>
                            {listArea.map((item, i) => (
                                <>
                                    <TableRow>
                                        <TableCell rowSpan={item.listTeams.length + 1} >
                                            {i + 1}
                                        </TableCell>
                                        <TableCell rowSpan={item.listTeams.length + 1} >
                                            {item.areaName}
                                        </TableCell>
                                    </TableRow>
                                    {item.listTeams.map((detail) => (
                                        <TableRow>
                                            <TableCell align="center">{detail.teamName}</TableCell>
                                            <TableCell align="center">{detail.amountPlot}</TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div >
    )
}

const useStyles = makeStyles({
    paperChart: {
        borderRadius: 15,
        marginTop: 20,
        padding: 50,
        minHeight: 300,
        backgroundColor: '#12203A'
    },
    paper: {
        borderRadius: 10, minHeight: 380,
        paddingLeft: 30, paddingRight: 30,
        paddingTop: 30, paddingBottom: 30
    },
    p40: {
        backgroundColor: '#F0F0F0',
        padding: 40
    },
    mockMap: {
        height: 400,
        width: '100%',
        backgroundImage: `url(${'https://img.freepik.com/free-vector/gps-pins-with-sign-icons-showing-city-map-blue-color_99087-107.jpg?w=2000'})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
    },
    boxCity: {
        backgroundColor: '#F08E26', width: '70%', height: 70,
        alignSelf: 'center', borderRadius: 15, marginTop: 15, marginBottom: 15
    },
    boxEnterPrise: {
        backgroundColor: '#12203A', width: '100%', height: 50,
        alignSelf: 'center', borderRadius: 10, marginTop: 15, marginBottom: 12
    },
    labelInBoxCity: {
        alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex'
    },
    paper4: {
        borderRadius: 16, paddingLeft: 30, paddingRight: 30, paddingTop: 30,
        paddingBottom: 30, marginTop: 30, overflow: 'hidden', flex: 1, marginRight: 20, marginLeft: 20,
        backgroundColor: '#12203A'
    },
    card_center: {
        flexDirection: 'column', display: 'flex',
        justifyContent: 'center', overflow: 'hidden', alignItems: 'center'
    },

})
