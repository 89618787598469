import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Tooltip, } from "@mui/material"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { StyledTableCell, StyledTableRow } from "../../components/StyledTable"
import { API_RES_STATUS } from "../../constants/apiResponseStatus"
import { ROUTE_PATH } from "../../constants/paths"
import useCategoryArea from "../../reactHook/useCategoryArea"
import useCategoryEmployee from "../../reactHook/useCategoryEmployee"
import useCategoryEnterprise from "../../reactHook/useCategoryEnterprise"
import useCategoryTeam from "../../reactHook/useCategoryTeam"
import { getAllPlotByRole } from "../../services/plot"
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box } from "@mui/system"
import StyledDataGrid from "../../components/StyledDataGrid"
import { AppContext } from "../../contexts/AppContext"
import { ROLES } from "../../constants/enums"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useSnackbar } from 'notistack'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import { getDefaultMeasurementDisplay } from "../../helper/measurementHelper"

export default function ListPlot() {
    const navigation = useNavigate()
    const { employee = {} } = useContext(AppContext)
    const { enterprises } = useCategoryEnterprise()
    const { areas, setFilterAreaByEnterpriseId } = useCategoryArea()
    const { teams, setFilterTeamByEnterpriseId, setFilterTeamByAreaId } = useCategoryTeam()
    const { employees, setFilterEmpByEnterpriseId, setFilterEmpByAreaId, setFilterEmpByTeamId } = useCategoryEmployee()

    const [tablePageSize, setTablePageSize] = useState(10)
    const [tableCurrentPage, setTableCurrentPage] = useState(0)
    const [tableTotalRow, setTableTotalRow] = useState(0)
    const [tableLoading, setTableLoading] = useState(false)

    const [filterEnterpriseId, setFilterEnterpriseId] = useState('')
    const [filterAreaId, setFilterAreaId] = useState('')
    const [filterTeamId, setFilterTeamId] = useState('')
    const [filterEmployeeId, setFilterEmployeeId] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const [plots, setPlots] = useState([])

    useEffect(() => {
        // ปิดการใข้งาน
        navigation('/')
        return
        //
    }, [])

    useEffect(() => {
        loadPlot()
    }, [filterEnterpriseId, filterAreaId, filterTeamId, filterEmployeeId, tablePageSize, tableCurrentPage])

    const loadPlot = async () => {
        setTableLoading(true)
        const axiosRes = await getAllPlotByRole({ filterEnterpriseId, filterAreaId, filterTeamId, filterEmployeeId, pageSize: tablePageSize, currentPage: tableCurrentPage + 1 })
        const json = JSON.parse(axiosRes.data)
        const { data, status, message } = json
        if (status === API_RES_STATUS.success) {
            const { listData = [], totalRow = 0 } = data
            listData.map(async (l, i) => {
                l.size = await getDefaultMeasurementDisplay(l.measurementName, l.size)
            })
            setPlots(listData)
            setTableTotalRow(totalRow)
        }
        setTableLoading(false)
    }

    const onInputChange = (value, key) => {
        switch (key) {
            case 'enterpriseId':
                setFilterAreaByEnterpriseId(value)
                setFilterTeamByEnterpriseId(value)
                setFilterEmpByEnterpriseId(value)
                setFilterEnterpriseId(value)

                setFilterAreaId('')
                setFilterTeamId('')
                setFilterEmployeeId('')
                break
            case 'areaId':
                setFilterAreaId(value)
                setFilterTeamByAreaId(value)
                setFilterEmpByAreaId(value)

                setFilterTeamId('')
                setFilterEmployeeId('')
                break
            case 'teamId':
                setFilterTeamId(value)
                setFilterEmpByTeamId(value)

                setFilterEmployeeId('')
                break
            case 'employeeId':
                setFilterEmployeeId(value)
                break
            default:
                break
        }
    }

    const columns = [
        {
            field: 'action-edit',
            headerName: "รายละเอียด",
            width: 120,
            renderCell: ({ row }) => {
                const empId = row["employeeId"]
                const plotId = row["id"]
                const startDate = row["lastActiveFramStartDate"]
                if (!empId) {
                    return ''
                }
                return <>
                    <IconButton
                        size="small"
                        onClick={() => checkFarmProcessing(plotId, startDate)}
                    >
                        <AssignmentIndIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => navigation(ROUTE_PATH.CREATE_PLOT, { state: { staffId: empId, plotId: plotId } })}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <Tooltip title="คัดลอก ID" placement='top'>
                        <IconButton
                            size="small"
                            onClick={() => {
                                const { id } = row
                                navigator.clipboard.writeText(id)
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </>
            }
        },
        {
            field: 'userFullName',
            headerName: 'ชื่อ-นามสกุล',
        },
        {
            field: 'userDeedNo',
            headerName: 'รหัสแปลง',
        },
        {
            field: 'userUid',
            headerName: 'หมายเลขทะเบียนเกษตรกร',
            width: 200
        },
        {
            field: 'size',
            headerName: 'ขนาดพื้นที่',
        },
        {
            field: 'plantName',
            headerName: 'ชนิดพืช',
        },
        {
            field: 'plantTypeName',
            headerName: 'ชนิดพันธุ์',
        },
        {
            field: 'LastActiveFramStartDate',
            headerName: 'วันที่ปลูก',
            valueGetter: (params) => {
                if (params.row["lastActiveFramStartDate"]) {
                    return dayjs(params.row["lastActiveFramStartDate"]).format("DD-MM-YYYY")
                } else {
                    return ''
                }
            }
        },
        {
            field: 'LastActiveFramEndDate',
            headerName: 'วันที่เก็บเกี่ยว',
            valueGetter: (params) => {
                if (params.row["lastActiveFramEndDate"]) {
                    return dayjs(params.row["lastActiveFramEndDate"]).format("DD-MM-YYYY")
                } else {
                    return ''
                }
            }
        },
        // {
        //     field: 'lastActiveFramQuantityProduct',
        //     headerName: "ผลผลิตรวม",
        // },
        {
            field: 'enterpriseName',
            headerName: "ชื่อบริษัท",
        },
        {
            field: 'areaName',
            headerName: "ชื่อพื้นที่"
        },
        {
            field: 'teamName',
            headerName: "ชื่อทีม"
        },
        {
            field: 'employeeFullName',
            headerName: "ชื่อพนักงาน",
            valueGetter: ({ row }) => {
                return `${row["employeeFirstName"]} ${row["employeeLastName"]}`
            }
        },
    ]


    const checkFarmProcessing = (plotId, startDate) => {
        if (startDate) {
            navigation(ROUTE_PATH.AREA_MANAGEMENT_DETAIL.replace(":id", plotId))
        } else {
            enqueueSnackbar("ไม่มีข้อมูลของฟาร์ม กรุณาสร้างฟาร์มให้เสร็จก่อน", { variant: 'error' })
        }
    }


    return <div style={{ margin: 40 }}>
        <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12}>
                {employee.role === ROLES.AdminLing &&
                    <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                        <InputLabel >เลือกบริษัท</InputLabel>
                        <Select
                            label="เลือกบริษัท"
                            value={filterEnterpriseId}
                            onChange={(e) => { onInputChange(e.target.value, 'enterpriseId') }}
                        >
                            <MenuItem value={''}>ทั้งหมด</MenuItem>
                            {enterprises.map((e) => {
                                return <MenuItem key={`select-enterprise-${e.id}`} value={e.id}>{e.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                }
                <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                    <InputLabel >เลือกพื้นที่</InputLabel>
                    <Select label="เลือกพื้นที่" value={filterAreaId} onChange={(e) => { onInputChange(e.target.value, 'areaId') }}>
                        <MenuItem value={''}>ทั้งหมด</MenuItem>
                        {areas.map((e) => {
                            return <MenuItem key={`select-area-${e.id}`} value={e.id}>{e.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                    <InputLabel >เลือกทีม</InputLabel>
                    <Select label="เลือกทีม" value={filterTeamId} onChange={(e) => { onInputChange(e.target.value, 'teamId') }}>
                        <MenuItem value={''}>ทั้งหมด</MenuItem>
                        {teams.map((e) => {
                            return <MenuItem key={`select-team-${e.id}`} value={e.id}>{e.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                    <InputLabel >เลือกพนักงาน</InputLabel>
                    <Select label="เลือกพนักงาน" value={filterEmployeeId} onChange={(e) => { onInputChange(e.target.value, 'employeeId') }} >
                        <MenuItem value={''}>ทั้งหมด</MenuItem>
                        {employees.map((e) => {
                            return <MenuItem key={`select-emp-${e.id}`} value={e.id}>{`${e.firstName} ${e.lastName}`}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ height: '70vh', width: '100%' }}>
                    <StyledDataGrid
                        rows={plots}
                        columns={columns}
                        paginationMode="server"
                        loading={tableLoading}
                        rowCount={tableTotalRow}
                        page={tableCurrentPage}
                        pageSize={tablePageSize}
                        pagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        onPageChange={(newPage) => setTableCurrentPage(newPage)}
                        onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
                    />
                </Box>
            </Grid>
        </Grid>

    </div>
}